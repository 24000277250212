import * as React from "react"

import Seo from "../../components/seo"
import ReduxWrapper from "../../redux/reduxWrapper"

const RequestedPage = props => {
  return (
    <React.Fragment>
      <Seo title="Reset Password" />
      <section className="loginSection">
        <div className="loginContainer" style={{ maxWidth: "500px", backgroundColor: "#d9ead3" }}>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <div className="mail-section" id="mail-section">
                <h1>Email Sent!</h1>
                <p>
                  If that email is associated to an account, we've sent you a password reset.
                  <br />
                  Please check your email (including spam, trash). Note this may take up to 12 minutes to
                  arrive, and will expire after 12 hours.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<RequestedPage {...props} />} />
export default WrappedPage
